/* eslint-disable jsx-a11y/anchor-is-valid */

const Footer = () => {
  return (
    <div className="text-slate-200">
      <footer>
        <div className="flex justify-between items-center">
          <div className=" basis-[10rem]">
            <div className="mt-3">
              <p className="footer-text mb-2">
                Quality King D.O.O Bulevar oslobodjenja 66, 21000, Novi Sad
                PIB:110115756 MB:21300683
              </p>
            </div>
          </div>
          <div className=" basis-[10rem]">
            <div className="mt-3">
              <p className="footer-text mb-2 flex flex-col justify-start">
                Banca Intesa:
                <br />
                160-483093-74
                <br />
                ProCredit:
                <br />
                220-140700-39
              </p>
            </div>
          </div>
          <div className=" basis-[10rem] justify-center items-center hidden sm:block">
            <img src={"/images/Logo.png"} height={150} width={150} />
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
