import React, { useState, useEffect } from "react";
import * as contentful from "contentful";
import { useTranslation } from "react-i18next";

const ProductPage = () => {
  const [product, setProduct] = useState({});
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation();

  const pathname = window.location.pathname;
  const part = pathname.split("/").slice(-1);
  const code = part[0];

  useEffect(() => {
    async function fetchData() {
      try {
        setLoading(true); // Set loading to true before fetching data
        const client = contentful.createClient({
          space: "q99i0c9t4uxv",
          accessToken: "iqg1FD9kopYGPYx90xJuIw77xOGcpZ4ZT2lWbs07RTY",
        });

        const entry = await client.getEntry(code);
        setLoading(false);
        setProduct(entry);
      } catch (error) {
        console.error("Error fetching data from Contentful:", error);
      }
    }
    fetchData();
  }, []);

  return (
    <div className="flex justify-center items-center">
      {loading ? (
        <div>Loading...</div>
      ) : (
        <div className="overflow-auto product-page  min-h-[75vh]">
          <h2 className="product-page__title mt-2 text-center">
            {product.fields.name}
          </h2>
          <div className="w-1/2">
            <img
              src={product.fields.image.fields.file.url}
              width={750}
              height={750}
            />
          </div>
          <div className="w-1/2 flex justify-center items-center">
            <div className="flex flex-col justify-center items-start text-slate-400 gap-2 text-2xl">
              <label>
                {t("product.barCode")} : {product.fields.code}
              </label>
              <label>
                {t("product.barCode2")} : {product.fields.code2}
              </label>
              <label>
                Raspoloživost:{" "}
                {product.fields.status ? "Na stanju" : "Nije na stanju"}
              </label>
              <p>{product.fields.text}</p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ProductPage;
