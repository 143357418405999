import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { FiFilter } from "react-icons/fi";
import { useDispatch } from "react-redux";
import { openFilterMenu } from "../../../../features/uiSlice";

const HeadeFilters = ({ count, onSearch }) => {
  const dispatch = useDispatch();
  const [searchQuery, setSearchQuery] = useState("");
  const { t } = useTranslation();

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
    onSearch(e.target.value);
  };

  return (
    <div className="flex-col gap-4 flex-center-between md:flex-row">
      <div className="w-full flex-center-between">
        <div className="gap-2 flex-align-center">
          <div
            className="grid w-10 h-10 md:hidden rounded-xl place-items-center bg-slate-100 sm:cursor-pointer hover:bg-slate-200 transition-a dark:bg-card-dark"
            onClick={() => dispatch(openFilterMenu())}
          >
            <FiFilter />
          </div>
        </div>
        <p>
          {t("products.current")} {count} {t("products.entity")}
        </p>
      </div>
      <div className="w-full gap-4 flex-center-between">
        {/* <select
          name=""
          id=""
          className="w-full px-3 py-2 bg-white border outline-none dark:border-dark dark:bg-main-dark"
        >
          <option value="">Sorty by</option>
          <option value="latest">Latest</option>
          <option value="cheapest">Cheapest</option>
          <option value="expensive">Expensive</option>
        </select> */}
        <input
          type="text"
          value={searchQuery}
          className="border outline-none bg-transparent dark:border-dark px-3 py-[0.35rem] w-full"
          placeholder={t('products.search')}
          onChange={handleSearchChange}
        />
      </div>
    </div>
  );
};

export default HeadeFilters;
