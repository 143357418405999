import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

import engTranslation from "./locales/eng/translation.json";
import srbTranslation from "./locales/srb/translation.json";

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    initImmediate: true,
    resources: {
      eng: {
        translation: engTranslation,
      },
      srb: {
        translation: srbTranslation,
      },
    },
    fallbackLng: "srb",
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
