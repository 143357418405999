import React from "react";
import { useSelector } from "react-redux";
import { dataStore } from "../../../features/dataSlice";
import SingleProductCard from "../../common/page-componets/SingleProductCard";

const PropertyList = ({ basis }) => {

  const { currentDataItems } = useSelector(dataStore);

  return (
    <div className="flex flex-wrap gap-4">
      {currentDataItems?.map((product, index) => (
        <SingleProductCard key={index} {...product} basis={basis} />
      ))}
    </div>
  );
};

export default PropertyList;
