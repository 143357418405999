import { useState, useEffect } from 'react';

const useSearch = (initialItems) => {
  const [items, setItems] = useState(initialItems);
  const [searchTerm, setSearchTerm] = useState('');

  const handleSearch = (query) => {
    setSearchTerm(query);
  };

  useEffect(() => {
    if (searchTerm.trim() === '') {
      setItems(initialItems);
      return;
    }
    const results = initialItems.filter(item =>
      item.fields.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setItems(results);
  }, [searchTerm, initialItems]);

  return { items, searchTerm, handleSearch };
};

export default useSearch;
