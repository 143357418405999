import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { setBrand, setCategory } from "../../../../features/filterSlice";

const AdvancedSearch = ({ products, onFilter }) => {
  const [selectedBrand, setSelectedBrand] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [filteredBrands, setFilteredBrands] = useState([]);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const categories = products
    .map((product) => product.fields.category)
    .filter((category) => category !== undefined);
  const brands = products
    .map((product) => product.fields.brand)
    .filter((brand) => brand !== undefined);

  useEffect(() => {
    setFilteredBrands([...new Set(brands)]);
  }, [products]);

  const handleCategoryChange = (event) => {
    const category = event.target.value;
    setSelectedCategory(category);
    dispatch(setCategory(category));

    const filteredBrands = products
      .filter((product) => product.fields.category === category)
      .map((product) => product.fields.brand);

    setFilteredBrands([...new Set(filteredBrands)]);
  };

  const handleBrandChange = (event) => {
    const brand = event.target.value;
    setSelectedBrand(brand);
    dispatch(setBrand(brand));
  };

  const handleFilter = () => {
    onFilter(selectedCategory, selectedBrand);
  };

  return (
    <div className="p-3 border dark:border-dark">
      <h1 className="font-semibold">{t("products.advanced")}</h1>
      <div className="mt-3">
        <select
          name=""
          id=""
          className="filter"
          value={selectedCategory}
          onChange={handleCategoryChange}
        >
          <option value="">{t("products.categories")}</option>
          {[...new Set(categories)].map((category) => (
            <option key={category} value={category}>
              {category}
            </option>
          ))}
        </select>
      </div>
      <div className="mt-3">
        <select
          name=""
          id=""
          className="filter"
          value={selectedBrand}
          onChange={handleBrandChange}
        >
          <option value="">{t("products.brands")}</option>
          {filteredBrands.map((brand) => (
            <option key={brand} value={brand}>
              {brand}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};

export default AdvancedSearch;
