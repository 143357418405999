import { useTranslation } from "react-i18next";

const AboutUs = () => {
  const { t } = useTranslation();

  return (
    <div className="pt-0 pb-20">
      <div className="flex flex-col gap-0">
        <div className="relative flex flex-col">
          <h1 className="heading">{t("contact.title")}</h1>
          <p className="mt-3">{t("home.description")}</p>
          <h1 className="heading mt-3">{t("about.title")}</h1>
          <p className="mt-3">{t("about.p1")}</p>
        </div>
        <img
          src="/images/care.jpeg"
          alt=""
          className="object-cover w-full h-full rounded-lg mt-10"
        />
      </div>
    </div>
  );
};

export default AboutUs;
