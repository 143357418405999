import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const LanguageSwitcher = () => {
  const { i18n } = useTranslation();
  const [selected, setSelected] = useState(i18n.language);
  const [show, setShow] = useState(false);

  useEffect(() => {
    const width = window.innerWidth;
    if (width <= 430) {
      setShow("hidden");
    }
  }, []);

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    setSelected(lng);
  };

  return (
    <div
      className={`gap-2 flex rounded-full border-[#c38fff] border-2 p-2 h-12 ${show}`}
    >
      <button
        onClick={() => changeLanguage("eng")}
        className={`${
          selected === "eng" ? "bg-slate-400 rounded-full px-2" : ""
        }`}
      >
        {selected === "eng" ? "English" : "Engleski"}
      </button>
      <button
        onClick={() => changeLanguage("srb")}
        className={`${
          selected === "srb" ? "bg-slate-400 rounded-full px-2" : ""
        }`}
      >
        {selected === "srb" ? "Srpski" : "Serbian"}
      </button>
    </div>
  );
};

export default LanguageSwitcher;
