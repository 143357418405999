import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  selectedCategory: "",
  selectedBrand: ""
};

const filterSlice = createSlice({
  name: "filter",
  initialState,
  reducers: {
    setCategory: (state, action) => {
      state.selectedCategory = action.payload;
    },
    setBrand: (state, action) => {
      state.selectedBrand = action.payload;
    }
  }
});

export const { setCategory, setBrand } = filterSlice.actions;

export default filterSlice.reducer;
