import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const LanguageSwitcherMobile = () => {
  const { i18n } = useTranslation();
  const [selected, setSelected] = useState(i18n.language);

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    setSelected(lng);
  };

  return (
    <div className={`gap-4 rounded-full border-[#c38fff] border-2 p-2 h-12 w-44 flex justify-center items-center`}>
      <button
        onClick={() => changeLanguage("eng")}
        className={`${
          selected === "eng" ? "bg-slate-400 rounded-full px-2" : ""
        }`}
      >
        {selected === "eng" ? "English" : "Engleski"}
      </button>
      <button
        onClick={() => changeLanguage("srb")}
        className={`${
          selected === "srb" ? "bg-slate-400 rounded-full px-2" : ""
        }`}
      >
        {selected === "srb" ? "Srpski" : "Serbian"}
      </button>
    </div>
  );
};

export default LanguageSwitcherMobile;
