import { useState } from "react";
import emailjs from "emailjs-com";
import { useTranslation } from "react-i18next";

const Form = () => {
  const { t } = useTranslation();
  const [formData, setFormData] = useState({
    name: "",
    surname: "",
    email: "",
    pib: "",
    message: "",
    company: "",
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      await emailjs.sendForm(
        "service_l5az8qq",
        "template_e5hr22h",
        e.target,
        "UeAdVNrFwFKGxC22_"
      );

      alert("Email sent successfully!");
      setFormData({
        name: "",
        surname: "",
        email: "",
        pib: "",
        message: "",
        company: "",
      });
    } catch (error) {
      console.error("Error sending email:", error);
      alert("Failed to send email");
    }
  };

  return (
    <form
      className=" bg-white rounded-3xl mt-7 mb-7 px-5 py-5 border-[3px] border-purple-400"
      onSubmit={handleSubmit}
    >
      <div className="flex-col gap-4 flex-align-center sm:flex-row mb-4">
        <div className="flex-1 w-full">
          <p className="text-black">{t("form.firstName")}</p>
          <input
            type="text"
            className="w-full input"
            placeholder={t("form.placeholder.firstName")}
            onChange={handleChange}
            name="name"
            value={formData.name}
          />
        </div>
        <div className="flex-1 w-full">
          <p className="text-black">{t("form.lastName")}</p>
          <input
            type="text"
            className="w-full input"
            placeholder={t("form.placeholder.lastName")}
            onChange={handleChange}
            name="surname"
            value={formData.surname}
          />
        </div>
      </div>
      <div className="flex-col gap-4 flex-align-center sm:flex-row mb-4">
        <div className="flex-1 w-full">
          <p className="text-black">{t("form.email")}</p>
          <input
            type="text"
            className="w-full input"
            placeholder={t("form.placeholder.email")}
            onChange={handleChange}
            name="email"
            value={formData.email}
          />
        </div>
        <div className="flex-1 w-full">
          <p className="text-black">{t("form.VAT")}</p>
          <input
            type="text"
            className="w-full input"
            placeholder={t("form.placeholder.VAT")}
            onChange={handleChange}
            name="pib"
            value={formData.pib}
          />
        </div>
        <div className="flex-1 w-full">
          <p className="text-black">{t("form.companyName")}</p>
          <input
            type="text"
            className="w-full input"
            placeholder={t("form.placeholder.companyName")}
            onChange={handleChange}
            name="company"
            value={formData.company}
          />
        </div>
      </div>
      <div className="mt-4">
        <p className="text-black">{t("form.message")}</p>
        <textarea
          rows={4}
          className="w-full input"
          placeholder={t("form.placeholder.message")}
          onChange={handleChange}
          name="message"
          value={formData.message}
        ></textarea>
      </div>
      <div className="mt-4 flex-center-center">
        <button className="btn btn-primary">{t("form.submit")}</button>
      </div>
    </form>
  );
};

export default Form;
