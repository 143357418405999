import { useTranslation } from "react-i18next";
import { FiCheck } from "react-icons/fi";

const Speciality = () => {
  const { t } = useTranslation();

  return (
    <div className="pt-2 pb-16">
      <div className="flex flex-wrap gap-10">
        <div className="flex-1 basis-[20rem]">
          <h1 className="heading">{t("home.goal")}</h1>
          <p className="mt-3">{t("home.main")}</p>
          <div className="mt-4">
            <div className="flex-align-center gap-x-2">
              <div className="icon-box text-primary !bg-primary/20">
                <FiCheck />
              </div>
              <label>Unilever</label>
            </div>
            <div className="mt-2 flex-align-center gap-x-2">
              <div className="icon-box text-primary !bg-primary/20">
                <FiCheck />
              </div>
              <label>BDF </label>
            </div>
            <div className="mt-2 flex-align-center gap-x-2">
              <div className="icon-box text-primary !bg-primary/20">
                <FiCheck />
              </div>
              <label>Procter & Gamble</label>
            </div>
            <div className="mt-2 flex-align-center gap-x-2">
              <div className="icon-box text-primary !bg-primary/20">
                <FiCheck />
              </div>
              <label>Victoria’s Secret</label>
            </div>
          </div>
        </div>
        <img
          src="https://cdn-icons-png.flaticon.com/512/9894/9894447.png"
          alt=""
          className="rounded-lg w-80 h-80 object-cover"
        />
      </div>
    </div>
  );
};

export default Speciality;
