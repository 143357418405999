import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const SingleProductCard = ({ fields, basis, sys }) => {
  const { t } = useTranslation();

  return (
    <div
      className={`flex-1 single-card ${
        basis ? basis : "basis-[18rem]"
      } shadow-light dark:border-card-dark border rounded-lg overflow-hidden relative group cursor-pointer`}
    >
      <div className="group !opacity-100 overflow-hidden relative">
        <Link to={`/products/${sys.id}`} className="!opacity-100">
          <img
            src={fields.image.fields.file.url}
            alt={fields.name}
            className="w-full single-card__img h-fit md:h-[250px] object-cover group-hover:scale-125 transition-a"
          />
        </Link>
      </div>
      <div className="p-3 single-card__content">
        <h2 className="text-lg font-bold capitalize single-card__title">
          {fields.name}
        </h2>
        <div className="flex justify-between mt-3">
          <div className="flex-align-center gap-x-2 single-card__holder">
            <p className="text-sm single-card__holder-inner">
              {" "}
              {t("product.barCode")} : {fields.code}
            </p>
            {fields.code2 && (
              <p className="text-sm">
                {" "}
                {t("product.barCode2")} : {fields.code2}
              </p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SingleProductCard;
