import { useTranslation } from "react-i18next";
import { Form } from "../components/contact";

const Contact = () => {
  const { t } = useTranslation();

  return (
    <div className="px-[3%] md:px-[6%] flex flex-col">
      <p className="text-purple-500 flex-center-center mt-5 text-4xl">
        {t("form.title")}
      </p>
      <Form />
    </div>
  );
};

export default Contact;
