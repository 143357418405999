import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

const useFilter = (products) => {
  const { selectedCategory, selectedBrand } = useSelector(state => state.filter);
  const [filteredProducts, setFilteredProducts] = useState([]);

  useEffect(() => {
    if (!selectedCategory && !selectedBrand) {
      setFilteredProducts(products);
    } else {
      const filtered = products.filter(product => {
        return (
          (!selectedCategory || product.fields.category === selectedCategory) &&
          (!selectedBrand || product.fields.brand === selectedBrand)
        );
      });
      setFilteredProducts(filtered);
    }
  }, [products, selectedCategory, selectedBrand]);

  return filteredProducts;
};

export default useFilter;
