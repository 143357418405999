import React, { useState, useEffect } from "react";

const ImageSlider = ({
  images,
  infinite = true,
  speed = 15000,
  slidesToShow = 1,
  slidesToScroll = 1,
}) => {
  const [currentSlide, setCurrentSlide] = useState(0);

  const totalSlides = images.length;

  const nextSlide = () => {
    setCurrentSlide((prevSlide) =>
      infinite
        ? (prevSlide + slidesToScroll) % totalSlides
        : Math.min(prevSlide + slidesToScroll, totalSlides - 1)
    );
  };

  const prevSlide = () => {
    setCurrentSlide((prevSlide) =>
      infinite
        ? (prevSlide - slidesToScroll + totalSlides) % totalSlides
        : Math.max(prevSlide - slidesToScroll, 0)
    );
  };

  useEffect(() => {
    const interval = setInterval(nextSlide, speed);

    return () => {
      clearInterval(interval);
    };
  }, [speed]);

  const visibleImages = images.slice(currentSlide, currentSlide + slidesToShow);

  return (
    <div className="relative 2xl:h-[550px] h-[280px]">
      <div className="image-slider overflow-hidden lg:h-auto h-full">
        <div className="slider-container flex space-x-4 lg:h-auto h-full">
          {visibleImages.map((image, index) => (
            <div key={index} className="slide">
              <img
                src={image}
                alt={`Slide ${index + 1}`}
                className="object-cover w-full h-full rounded-lg mt-10"
              />
            </div>
          ))}
        </div>
      </div>
      <button
        className="prev-button absolute top-1/2 left-0 transform -translate-y-1/2 bg-gray-200 p-2 rounded-full"
        onClick={prevSlide}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-6 w-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M15 19l-7-7 7-7"
          />
        </svg>
      </button>
      <button
        className="next-button absolute top-1/2 right-0 transform -translate-y-1/2 bg-gray-200 p-2 rounded-full"
        onClick={nextSlide}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-6 w-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M9 5l7 7-7 7"
          />
        </svg>
      </button>
    </div>
  );
};

export default ImageSlider;
