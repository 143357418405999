/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { BiMenu } from "react-icons/bi";
import { FiDelete, FiMoon, FiSun } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import { Link, NavLink, useNavigate } from "react-router-dom";
import {
  closeDropdown,
  closeSidebar,
  openSidebar,
  toggleDarkMode,
  uiStore,
} from "../../features/uiSlice";
import LanguageSwitcher from "./LanguageSwitcher";
import LanguageSwitcherMobile from "./LanguageSwitcherMobile";

const Navbar = () => {
  const rootDoc = document.querySelector(":root");
  const { darkMode, isSidebarOpen } = useSelector(uiStore);
  const [showSearchBar, setShowSearchBar] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { i18n } = useTranslation();
  const { t } = useTranslation();

  // Dark mode toggle
  const handleDarkMode = () => {
    dispatch(toggleDarkMode());
  };

  // Store darkmode value to localStorage;
  useEffect(() => {
    if (darkMode) rootDoc.classList.add("dark");
    else rootDoc.classList.remove("dark");
    localStorage.setItem("Martvilla-theme-mode", JSON.stringify(darkMode));
  }, [darkMode]);

  const handleClose = (e) => {
    if (!e.target.classList.contains("link")) {
      dispatch(closeDropdown());
    }
  };

  const handleCloseSidebar = (e) => {
    if (e.target.classList.contains("mobile-modal")) dispatch(closeSidebar());
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    navigate("/search");
  };

  return (
    <div
      className="navbar h-[110px] font-bold sticky w-full z-20 top-0 left-0 px-[2%] bg-black md:px-[6%] flex-center-between py-[0.35rem] border-b backdrop-blur-sm dark:border-dark text-[#fff]"
      onMouseOver={handleClose}
    >
      <Link to="/" className="flex-shrink-0 flex-align-center gap-x-1">
        <img
          src={"/images/Logo.png"}
          height={150}
          width={150}
          className="cursor-pointer"
        />
      </Link>

      <div className="flex-align-center gap-x-4">
        {/*-------------------------------------- Desktop Menu------------------------------------- */}
        <div
          className={`hidden md:flex-align-center ${
            showSearchBar && "!hidden"
          }`}
        >
          <div className="flex gap-5 text-2xl">
            <NavLink
              to={"/"}
              end
              className="w-fit before:!hidden"
              onClick={() => dispatch(closeSidebar())}
            >
              {t("nav.home")}
            </NavLink>
            <NavLink
              to={"/products"}
              end
              className="w-fit before:!hidden"
              onClick={() => dispatch(closeSidebar())}
            >
              {t("nav.products")}
            </NavLink>
            <NavLink
              to={"about-us"}
              end
              className="w-fit before:!hidden"
              onClick={() => dispatch(closeSidebar())}
            >
              {t("nav.about")}
            </NavLink>
            <NavLink
              to={"/contact"}
              end
              className="w-fit before:!hidden"
              onClick={() => dispatch(closeSidebar())}
            >
              {t("nav.contact")}
            </NavLink>
          </div>
        </div>
        {/*---------------------------------------- Mobile Menu------------------------------------- */}
        <div
          className={`lg:hidden mobile-modal fixed w-screen h-screen top-0 left-0 bg-black/50 z-50 opacity-0 pointer-events-none transition-a text-black  ${
            isSidebarOpen && "open"
          }`}
          onClick={handleCloseSidebar}
        >
          <div
            className={`mobile-dialog overflow-auto absolute flex flex-col space-y-4 p-3 bg-white dark:bg-card-dark h-screen max-w-[300px] w-full -translate-x-[500px] transition-a ${
              isSidebarOpen && "open"
            }`}
          >
            <div className="border-b flex-center-between dark:border-slate-800">
              <p className="uppercase">menu</p>
              <div
                className="icon-box md:hidden"
                onClick={() => dispatch(closeSidebar())}
              >
                <FiDelete />
              </div>
            </div>
            <NavLink
              to={"/"}
              end
              className="w-fit before:!hidden"
              onClick={() => dispatch(closeSidebar())}
            >
              {t("nav.home")}
            </NavLink>
            <NavLink
              to={"/products"}
              end
              className="w-fit before:!hidden"
              onClick={() => dispatch(closeSidebar())}
            >
              {t("nav.products")}
            </NavLink>
            <NavLink
              to={"about-us"}
              end
              className="w-fit before:!hidden"
              onClick={() => dispatch(closeSidebar())}
            >
              {t("nav.about")}
            </NavLink>
            <NavLink
              to={"/contact"}
              end
              className="w-fit before:!hidden"
              onClick={() => dispatch(closeSidebar())}
            >
              {t("nav.contact")}
            </NavLink>
            <LanguageSwitcherMobile />
          </div>
        </div>
        <div className="space-x-2 flex-align-center">
          <div
            className=" shadow-md icon-box dark:bg-dark-light cursor-pointer"
            onClick={() => {
              handleDarkMode();
            }}
          >
            {darkMode ? <FiSun /> : <FiMoon />}
          </div>
          {/*------------------------------- Mobile Menu Toogle------------------------- */}
          <div
            className="icon-box md:hidden"
            onClick={() => dispatch(openSidebar())}
          >
            <BiMenu />
          </div>
        </div>
        <LanguageSwitcher />
      </div>
    </div>
  );
};

export default Navbar;
