import {
  Brands,
  Counter,
  Services,
} from "../components/common/page-componets";
import {
  Hero,
  Speciality,
} from "../components/home/home-1";

const Home = () => {
  return (
    <div className="pt-5 md:pt-16 px-[3%] md:px-[6%]">
      <Hero />
      <Speciality />
    </div>
  );
};

export default Home;
