import Slider from "./Slider.jsx";
import { useTranslation } from "react-i18next";

const Hero = () => {
  const { t } = useTranslation();

  const images = [
    "/images/1.jpg",
    "/images/2.jpg",
    "/images/3.jpg",
    "/images/4.jpg",
    "/images/5.jpg",
    "/images/6.jpg",
    "/images/7.jpg",
    "/images/8.jpg",
    "/images/9.jpg",
    "/images/10.jpg",
    "/images/11.jpg",
    "/images/12.jpg",
    "/images/13.jpg",
    "/images/14.jpg",
  ];

  return (
    <div className="z-0 md:-mt-10 pt-0 pb-5 md:pb-0">
      <div className="overflow-hidden rounded-xl w-full flex-center-center">
        <Slider
          images={images}
          infinite={true}
          speed={4000}
          slidesToShow={1}
          slidesToScroll={1}
        />
      </div>
    </div>
  );
};

export default Hero;
