import { createClient } from "contentful";
import React, { useEffect, useState } from "react";
import { FiDelete } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import {
  AdvancedSearch,
  HeadeFilters,
  Pagination,
} from "../components/common/page-componets";
import { PropertyList } from "../components/property";
import { closeFilterMenu, uiStore } from "../features/uiSlice";
import useSearch from "../hooks/useSearch";
import useFilter from "../hooks/useFilter";

const PropertyFive = () => {
  const { isFilterMenuOpen } = useSelector(uiStore);
  const itemsPerPage = 12;
  const dispatch = useDispatch();
  const handleCloseFiltermenu = (e) => {
    if (e.target.classList.contains("filter-modal"))
      dispatch(closeFilterMenu());
  };

  const [products, setProducts] = useState([]);

  useEffect(() => {
    const client = createClient({
      space: "q99i0c9t4uxv",
      accessToken: "iqg1FD9kopYGPYx90xJuIw77xOGcpZ4ZT2lWbs07RTY",
    });

    client
      .getEntries({
        content_type: "product",
        limit: 1000,
        order: "fields.name",
      })
      .then((response) => {
        setProducts(response.items);
      })
      .catch(console.error);
  }, []);

  const { items: results, handleSearch } = useSearch(products);
  const filteredResults = useFilter(results);

  return (
    <div className="pt-20 px-[3%] md:px-[6%]">
      <HeadeFilters count={products.length} onSearch={handleSearch} />
      <div className="grid md:grid-cols-4 gap-x-14 mt-5">
        <div className="md:col-span-3 mt-5 md:mt-0 h-fit md:sticky top-0 ">
          <PropertyList />
          <Pagination itemsPerPage={itemsPerPage} pageData={filteredResults} />
        </div>
        <div className=" md:col-span-1 row-start-3 md:row-start-auto h-fit md:sticky top-0">
          <div
            className={`filter-modal ${isFilterMenuOpen && "open"}`}
            onClick={handleCloseFiltermenu}
          >
            <div className={`filter-dialog ${isFilterMenuOpen && "open"}`}>
              <div className="flex-center-between border-b dark:border-dark md:hidden">
                <div
                  className="icon-box md:hidden"
                  onClick={() => dispatch(closeFilterMenu())}
                >
                  <FiDelete />
                </div>
                <p className="uppercase">Filters</p>
              </div>
              <AdvancedSearch products={products} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PropertyFive;
