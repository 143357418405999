import {
  AboutUs,
  Brands,
  Feeds,
  Team,
  Testimonial,
} from "../components/common/page-componets";

const About = () => {
  return (
    <div className="pt-10 px-[3%] md:px-[6%]">
      <AboutUs />
    </div>
  );
};

export default About;
